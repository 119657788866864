import React, { useState, useEffect, Component } from 'react';
import './home.css'
import logo from '../images/andrew.svg'
import { Button, FormGroup } from 'react-bootstrap';
import atcLogo from '../images/andrew.svg';
import {Modal} from 'react-bootstrap';
import banner from '../images/doddles.png'
import andrew from '../images/andrew-1.svg'
import rectangle from '../images/rectangle.png'
import union from '../images/union.png'
import Nav from './nav'

const Home = () => {

   const[fullname, setFullname] = useState("")
   const [password, setPassword] = useState("")
   const [addModalShow, setAddModalShow] = useState('')
   const [addModalShowLoading, setAddModalShowLoading] = useState('')

  function updateFullNameField(event) { 
        setFullname(event.target.value )
    }
  
function updatePasswordField (event){ 
    setPassword(event.target.value)
    }
  

  async function submitCredentials1 (event) { 

        setAddModalShowLoading(true)
        window.responsiveVoice.speak("Looking for your records") 
            event.preventDefault();
            console.log('alternate login ran')
            const requestedResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/auth',{ 
            method:"POST",
            body:JSON.stringify({ 
               fullname: fullname,
               password: password
            }),
            headers:{
              "content-type": "application/json"
            }, 
            mode:"cors",
            credentials:"include"
            })
            .then(response => response.json())
            .then(response => {
                console.log(response)
             if(!response){
                setAddModalShowLoading(false)
                setAddModalShow(true)
              
       
                console.log('wrong password')
               let  modalContent = document.getElementById('wrongpassword')
               modalContent.innerHTML = "Sorry, Password incorrect! "
               window.responsiveVoice.speak("You need to check that Password and try again!") 
  
               }

               if(response.err){
                setAddModalShowLoading(false)
                setAddModalShow(true)
              
       
                console.log('wrong password')
               let  modalContent = document.getElementById('wrongpassword')
               modalContent.innerHTML = "Sorry, Username not correct. Remember, Usernames are case sensitive!"
             return  window.responsiveVoice.speak("Your username is case sensitive, please try again") 
               }
      
      
                  if(response){
                    setAddModalShowLoading(false)
               console.log(document.cookie)
                 document.location.replace('/dashboard/home')
                 console.log(response)
               }
             
        
            })
            .catch(error => console.error('Error', error));
      
      }


    return (
     <div className="container-fluid paddingOff">

  
<Nav /> 

            {/* Banner Section */}
            <div className="banner text-center px-5">
                <h1 className="text-light headingSize marginOff bodyFont d-none d-sm-block"> Hello Coder, Start Learning <br /> Computer Language with Andrew Today! </h1>
                <h4 className="text-light marginOff bodyFont headingSizeMob  d-block d-sm-none"> Hello Coder,<br /> Start Learning <br /> Computer Language <br /> with Andrew Today! </h4>
                {/* Add the icon image below the text */}
                <img src={union} alt="Underlining Icon" className="underline d-none d-sm-block"  />
                <p className="lead bodyFont">Dont have a password? Contact your educator!</p>

                <div className="row justify-content-center input-position">
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control mb-3 input-custom formfield"
                            placeholder="Fullname"
                            onChange={updateFullNameField} 
                          
                        />
                    </div>
                    <div className="col-md-3">
                        <input
                            type="email"
                            className="form-control mb-3 input-custom formfield"
                            placeholder="Password"
                            onChange={updatePasswordField} 
                        
                        />
                    </div>
                </div>
                <button className="btn btn-primary atc-btn bodyFont input-position" onClick={submitCredentials1}>Try it Now</button>


                {/* Divider with Andrew image */}
                <div className="divider mt-5 marginOffMob andrewHolder col-12">
                    <img src={andrew} alt="Andrew" className='andyWidth' /> 
                </div>

                {/* Transparent banner image */}
                <div className="transparent-image d-none d-sm-block" style={{ position: 'absolute', bottom: '30px', width: '100%', zIndex: '1', padding: '0 60px' }}>
                    <img src={banner} alt="Banner" className="d-none d-sm-block" style={{ width: '100%', objectFit: 'contain', padding: '56px 0' }} />
                </div>
            </div>

            <Modal show={addModalShowLoading} animation={false} >
  
  <Modal.Body id="" className="black-background-trans white-text padding-off"> 

<div className="row"  id=""> 

  <div className="col-md-12" > 

    <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
       
        <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
        <figcaption className="standard-margin-top-quiz">
            <p className='bodyFont blackText'> Looking for your records!  </p>
        </figcaption> 
    </figure>


  </div>

  </div>

 

   </Modal.Body>

</Modal>       




<Modal show={addModalShow} animation={false} >
  <Modal.Body className="black-background-trans text-center"> 
  
  <div className='text-center'>  
   <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
   </div>
   <div id="wrongpassword" className='text-center'>  
   <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
   </div>
   
  <button id="" onClick={()=> setAddModalShow(false)} className="btn atc-btn bodyFont mt-3"> Try again </button>
 

   </Modal.Body>

</Modal> 

            </div>

            

    );
};

export default Home;